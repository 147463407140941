import { UseFormRegister } from "react-hook-form";
import s from "./Select.module.scss";

interface Option {
	value: string;
	label: string;
}

interface Props {
	register: UseFormRegister<any>;
	placeholder: string;
	name: string;
	options: Option[];
}

export default function Select({
	register,
	placeholder,
	name,
	options,
}: Props) {
	return (
		<select {...register(name)} className={s.select} defaultValue="">
			<option value="">{placeholder}</option>
			{options.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</select>
	);
}
