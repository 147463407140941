import { ComponentProps } from "react";
import cx from "classnames";
import { UseFormRegister, RegisterOptions } from "react-hook-form";

import { ValidationError } from "@/components/Form";

import s from "./Input.module.scss";
interface Props extends ComponentProps<"input"> {
	placeholder?: string;
	name: string;
	type: "text" | "email" | "password" | "number" | "url" | "tel";
	register: UseFormRegister<any>;
	errors?: {
		[x: string]: any;
	};
	options?: RegisterOptions;
	label: string;
	containerClass?: string;
	required?: boolean;
}

function Input({
	type,
	placeholder,
	label,
	register,
	options,
	errors,
	name,
	required = false,
	containerClass,
	...props
}: Props) {
	const hasError = errors && !!errors[name];
	const isRequired = required
		? options?.required || "Dit veld is verplicht."
		: false;

	return (
		<div className={cx(s.container, containerClass)}>
			<input
				{...props}
				{...register(name, {
					...options,
					required: isRequired,
				})}
				aria-label={label}
				aria-placeholder=""
				className={cx(s.input, { [s.error]: hasError }, props.className)}
				type={type}
				placeholder={placeholder}
				aria-describedby={hasError ? `${name}-error` : undefined}
				aria-invalid={hasError}
			/>
			<ValidationError errors={errors} name={name} />
		</div>
	);
}

export default Input;
