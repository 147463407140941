import { ComponentProps, ReactNode, forwardRef, ForwardedRef } from "react";

type FlexDirection = "row" | "row-reverse" | "column" | "column-reverse";
type FlexJustify =
	| "flex-start"
	| "flex-end"
	| "center"
	| "space-between"
	| "space-around"
	| "initial"
	| "inherit";
type FlexAlign =
	| "stretch"
	| "center"
	| "flex-start"
	| "flex-end"
	| "baseline"
	| "initial"
	| "inherit";

interface FlexOptionalProps extends ComponentProps<"div"> {
	className?: string;
	flexDirection?: FlexDirection;
	justifyContent?: FlexJustify;
	flexWrap?: "wrap" | "nowrap" | "wrap-reverse";
	alignItems?: FlexAlign;
	gap?: number;
	rowGap?: number;
	columnGap?: number;
}

export interface FlexProps extends FlexOptionalProps {
	children: ReactNode;
	inline?: boolean;
}

export interface FlexBoxProps extends FlexProps {
	flexDirection: FlexDirection;
}

interface FlexPropsWithReverse extends FlexProps {
	reverse?: boolean;
}

export const FlexBox = forwardRef(function FlexBox(
	{
		children,
		className,
		style,
		inline,
		flexDirection,
		justifyContent,
		flexWrap,
		alignItems,
		gap,
		rowGap,
		columnGap,
		...rest
	}: FlexBoxProps,
	ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
	const gapStyles = {
		gap,
		rowGap,
		columnGap,
	};

	if (!gapStyles.columnGap) delete gapStyles.columnGap;
	if (!gapStyles.rowGap) delete gapStyles.rowGap;

	return (
		<div
			ref={ref}
			{...rest}
			className={className}
			style={{
				display: inline ? "inline-flex" : "flex",
				flexDirection,
				justifyContent,
				flexWrap,
				alignItems,
				...gapStyles,
				...style,
			}}
		>
			{children}
		</div>
	);
});

export const FlexRow = forwardRef(function FlexRow(
	{ reverse = false, children, ...rest }: FlexPropsWithReverse,
	ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
	return (
		<FlexBox
			flexDirection={reverse ? "row-reverse" : "row"}
			{...rest}
			ref={ref}
		>
			{children}
		</FlexBox>
	);
});

export const FlexColumn = forwardRef(function FlexColumn(
	{ reverse = false, children, ...rest }: FlexPropsWithReverse,
	ref: ForwardedRef<HTMLDivElement>
): JSX.Element {
	return (
		<FlexBox
			flexDirection={reverse ? "column-reverse" : "column"}
			{...rest}
			ref={ref}
		>
			{children}
		</FlexBox>
	);
});
