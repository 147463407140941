import * as RadioGroup from "@radix-ui/react-radio-group";

import { FlexBox } from "../../layout/FlexBox";
import RadioItem, { RadioItemProps } from "./RadioItem";
import style from "./RadioGroup.module.scss";

interface Option extends Omit<RadioItemProps, "id" | "name"> {
	id?: string;
}

export interface RadioGroupProps extends RadioGroup.RadioGroupProps {
	name: string;
	options: Option[];
}

function CustomRadioGroup({
	options = [],
	orientation = "vertical",
	name,
	...props
}: RadioGroupProps) {
	return (
		<RadioGroup.Root
			{...props}
			className={style.root}
			orientation={orientation}
			asChild
		>
			<FlexBox
				flexDirection={orientation === "horizontal" ? "row" : "column"}
				rowGap={10}
				columnGap={10}
			>
				{options.map((option) => (
					<RadioItem
						key={option.value}
						{...option}
						name={name}
						id={option.id || `${name}_${option.value}`}
					/>
				))}
			</FlexBox>
		</RadioGroup.Root>
	);
}

export default CustomRadioGroup;
