import * as Switch from "@radix-ui/react-switch";
import * as Label from "@radix-ui/react-label";

import { Text } from "@/components/Typography";
import { FlexColumn, FlexRow } from "../../layout/FlexBox";
import cx from "classnames";

import s from "./Switch.module.scss";

export interface SwitchProps extends Switch.SwitchProps {
	id: string;
	isDisabled?: boolean;
	title: string;
	subtitle?: string;
	onCheckedChange: (checked: boolean) => void;
}

function CustomSwitch({
	id,
	title,
	subtitle,
	isDisabled = false,
	onCheckedChange,
	...props
}: SwitchProps) {
	return (
		<FlexRow
			justifyContent="space-between"
			alignItems="center"
			className={cx(s.switch, { [s.isDisabled]: isDisabled })}
		>
			<Label.Root className={s.switch__label} htmlFor={id}>
				<FlexColumn>
					<Text className={s.switch__title}>{title}</Text>
					{subtitle && <Text className={s.switch__subtitle}>{subtitle}</Text>}
				</FlexColumn>
			</Label.Root>
			<Switch.Root
				{...props}
				id={id}
				className={s.switch__wrapper}
				disabled={isDisabled}
				onCheckedChange={onCheckedChange}
			>
				<Switch.Thumb className={s.switch__thumb} />
			</Switch.Root>
		</FlexRow>
	);
}

export default CustomSwitch;
