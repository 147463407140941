import { PropsWithRef, RefCallback } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import * as Label from "@radix-ui/react-label";

import { FlexRow } from "../../layout/FlexBox";
import s from "./RadioGroup.module.scss";

export type RadioItemProps = Omit<RadioGroup.RadioGroupItemProps, "asChild"> &
	PropsWithRef<{
		name: string;
		id: string;
		label: string;
		ref?: RefCallback<HTMLButtonElement>;
	}>;

function RadioItem(option: RadioItemProps) {
	return (
		<FlexRow alignItems="center" gap={6} className={s.item__root}>
			<RadioGroup.Item className={s.item} {...option}>
				<RadioGroup.Indicator className={s.item__indicator} />
			</RadioGroup.Item>
			<Label.Root htmlFor={option.id} className={s.item__label}>
				{option.label}
			</Label.Root>
		</FlexRow>
	);
}

export default RadioItem;
