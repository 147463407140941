import { ComponentPropsWithoutRef } from "react";
import s from "./Label.module.scss";

interface Props extends ComponentPropsWithoutRef<"label"> {
	name: string;
}

export function Label({ name, children }: Props) {
	return (
		<label id={`${name}-label`} htmlFor={name} className={s.label}>
			{children}
		</label>
	);
}
