import { ErrorMessage } from "@hookform/error-message";
import { Text } from "@/components/Typography";

import s from "./ValidationError.module.scss";

interface Props {
	name: string;
	errors?: {
		[x: string]: any;
	};
}

export default function ValidationError({ name, errors }: Props) {
	return (
		<ErrorMessage
			errors={errors}
			name={name}
			as={
				<Text id={`${name}-error`} as="span" size="small" className={s.error} />
			}
		/>
	);
}
