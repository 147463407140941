import * as Checkbox from "@radix-ui/react-checkbox";
import * as Label from "@radix-ui/react-label";

import { FlexRow } from "../../layout/FlexBox";
import { Icon } from "../../Icon/Icon";

import s from "./Checkbox.module.scss";

export interface CheckboxProps extends Checkbox.CheckboxProps {
	label: Label.LabelProps["children"];
}

function CustomCheckbox({ id, label, ...props }: CheckboxProps) {
	return (
		<FlexRow alignItems="flex-start" columnGap={16}>
			<Checkbox.Root {...props} id={id} className={s.root}>
				<Checkbox.Indicator asChild>
					<Icon icon="check" />
				</Checkbox.Indicator>
			</Checkbox.Root>
			<Label.Root htmlFor={id} className={s.label}>
				{label}
			</Label.Root>
		</FlexRow>
	);
}

export default CustomCheckbox;
