import { ComponentProps } from "react";
import cx from "classnames";
import { UseFormRegister, RegisterOptions } from "react-hook-form";

import s from "./Textarea.module.scss";
import { Label } from "@/components/Form";

interface Props extends ComponentProps<"textarea"> {
	placeholder?: string;
	register: UseFormRegister<any>;
	name: string;
	errors?: {
		[x: string]: any;
	};
	options?: RegisterOptions;
	label: string;
	containerClassName?: string;
}

function Textarea({
	placeholder,
	label,
	register,
	options,
	errors,
	name,
	required = false,
	containerClassName,
	...props
}: Props) {
	const hasError = errors && !!errors[name];
	const isRequired = required
		? options?.required || "Dit veld is verplicht."
		: false;

	return (
		<div className={cx(s.container, containerClassName)}>
			<Label name={name}>{label}</Label>
			<textarea
				{...props}
				{...register(name, {
					...options,
					required: isRequired,
				})}
				aria-labelledby={`${name}-label`}
				aria-placeholder=""
				className={cx(s.textarea, { [s.error]: hasError }, props.className)}
				aria-describedby={hasError ? `${name}-error` : undefined}
				aria-invalid={hasError}
			/>
		</div>
	);
}

export default Textarea;
